import React, { useEffect, useState } from 'react'
import AppHeader from '../components/AppHeader'
import portalServices from '../portalServices';
import { useNavigate } from 'react-router-dom';


function SelectQueuePage() {

  const navigate = useNavigate();
  const [queuList,setQueueList] = useState(null);
  const getQueueList = () =>{
    portalServices.getQueueList().then(res=>{
      setQueueList(res.data);
    }).catch(error=>{

    })
  }
  useEffect(()=>{
    getQueueList();
  },[])

  const selectQueue = (queue) =>{
    if(queue.isOpenNow){
      navigate("/services/"+queue.id)
    }
  }

  

  return (
    <>
        <AppHeader/>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                    {queuList && queuList.map(queue=>
                      <div key={queue.id} className="card px-3 py-2 mb-2" onClick={()=>selectQueue(queue)} style={{cursor:"pointer"}}>
                          <div className='fw-bold'>{queue.title}</div>
                          {queue.isOpenNow ?
                            <div className='text-success' >Waiting Time {queue.waitingTime} Minutes</div>
                            :
                            <div className='text-danger' >Currently closed</div>
                          }
                      </div>
                    )}
                </div>
            </div>
        </div>
    </>
  )
}

export default SelectQueuePage