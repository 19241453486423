import React,{useState,useEffect} from 'react'
import portalServices from '../portalServices';

import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import { LinearProgress } from '@mui/material';
import { useAlert } from '../../../context/AlertContext';
import { useNavigate } from 'react-router-dom';
import { useSocket } from '../../../context/SocketContext';
import VisitorImageCapture from './VisitorImageCapture';
import io from 'socket.io-client'


function CustomerCheckinForm({queueId,serviceId,customerToken}) {
  
  const {AppAlert} = useAlert();
  const {connectionUrl} = useSocket()
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState(null);
  const validationRule = Yup.object({});
  const [formValidationRule, setFormValidationRule] = useState(null);
  const [profileImage,setProfileImage] = useState(null);
  const [formConfig,setFormConfig] = useState(null);
  const [savingInprogress,setSavingStatus] = useState(false);
  
  const idTypeList = [
    {label:"NA", value:"0"},
    {label:"Driving L", value:"1"},
    {label:"Aadhar card", value:"2"},
    {label:"Pan card", value:"3"},
    {label:"Employee Id", value:"4"},
    {label:"Voter Id", value:"5"},
    {label:"Passport Id", value:"6"},
  ]

  const getFormConfig = () =>{
    portalServices.getFormConfig(queueId).then(res=>{
      setFormConfig(res.data);
    })
  }
  useEffect(()=>{
    getFormConfig();
  },[queueId])


  const prepareValidationRule = () =>{
    let rules = {};
    console.log(formConfig);
    if(formConfig.emailRequired == 1){
        rules.email=Yup.string().email().required("Required");
    }
    
    if(formConfig.addressRequired == 1){
        rules.address=Yup.string().required("Required");
    }
    if(formConfig.ageRequired == 1){
        rules.age=Yup.string().required("Required");
    }
    if(formConfig.genderRequired == 1){
      rules.gender=Yup.string().required("Required");
    }
    if(formConfig.idProofRequired == 1){
        rules.idType=Yup.string().required("Required");
        //rules.idProof=Yup.string().required("Required");


        rules.idProof=Yup.string().when(['idType'],(idType,schema,val)=>{
          console.log("idType is",idType[0]);
          if(idType[0] != 0){
              return Yup.string().required("Required");
          }
        });
        


    }
    let _formValidationRule = Yup.object(rules)
    setFormValidationRule(_formValidationRule)
  }

  useEffect(()=>{
    if(formConfig !== null ){
      prepareValidationRule();
    }
  },[formConfig])

  
  const initialValues = {
    firstName:"",
  }


  const onSubmit = (values, onSubmitProps) => {
    //setSavingStatus(true);
    if(profileImage !== null ){
        values.profileImage = profileImage;
    }
    let payload = {
      customerDetails :values,
      queueId:queueId,
      serviceId:serviceId,
      customerToken:customerToken

    }
    portalServices.doCheckin(payload).then(res=>{
      //socket.emit("send_new_token",{message:"new token received"})
      socketEmit()
      localStorage.setItem("customerToken",res.customerToken);
      navigate("/token-details");
      console.log(res);
    }).catch(error=>{
      AppAlert(error.msg)
    })
  }

  const socketEmit = ()=>{
    let socket = io.connect(connectionUrl)
      socket.emit("send_new_token",{message:"new token received"})
      setTimeout(() => {
        socket.close()
      }, 5000);
  }


  return (
    <>
      {formConfig &&
      <Formik
        initialValues={formValues || initialValues}
        validationSchema={formValidationRule || validationRule}
        onSubmit={onSubmit}
        enableReinitialize validateOnMount
      >
        {
          (formik) => {
              //console.log(formik.values);
              return (
                <Form>
                  <div className="row mt-4">                                                       
                    
                    {formConfig.photoRequired == 1 &&
                      <div className="col-md-12 text-center">
                        <VisitorImageCapture setProfileImage={setProfileImage} customerToken={customerToken}/>
                      </div>
                    }


                    <FormikControl control="input" formik={formik} type="text" 
                        label="Your Name" name="firstName"
                        className="col-md-12"
                    />
                    {formConfig.emailRequired == 1 && 
                    <FormikControl control="input" formik={formik} type="email" 
                        label="Email*" name="email" 
                        className="col-md-12"
                    />
                    }
                    
                    {formConfig.ageRequired == 1 && 
                    <FormikControl control="input" formik={formik} type="number" 
                        label="Age*" name="age"
                        className="col-md-12 "
                    />
                    }

                    {formConfig.genderRequired == 1 && 
                      <FormikControl control="autoselect" formik={formik} 
                          label="Gender" name="gender"  
                          options={[{value:1,label:"Male"},{value:2,label:"Female"}]} multiple={false} 
                          className="col-md-4 col-xs-6 p-2"
                      />
                                        
                    }
                    {formConfig.idProofRequired == 1 && 
                        <div className="col-md-12">
                            <div className="row mt-4">
                                <div className="col-md-6">
                                    <FormikControl control="autoselect" formik={formik} 
                                        label="ID Type" name="idType"  
                                        options={idTypeList} multiple={false} 
                                    />
                                </div>
                                {(formik.values.idType  !== undefined  && formik.values.idType != 0 )&&
                                  <FormikControl control="input" formik={formik} type="text" 
                                      label="Id Proff Details" name="idProof"
                                      className="col-md-6"
                                  />
                                }
                            </div>
                        </div>
                    }
                    {formConfig.addressRequired == 1 && 
                    <FormikControl control="input" formik={formik} type="text" 
                        label="Address" name="address"
                        className="col-md-12"
                    />
                    }
                    
                  </div>
                  <div className='row mt-4'>
                      <div className='col-md-12'>
                        {savingInprogress ? 
                          <LinearProgress/>
                          :
                          <>
                            <button className='btn btn-outline-primary float-end' type='submit' >Check In</button>
                          </>
                        }
                      </div>                      
                  </div>
                </Form>
              )
          }
        }
      </Formik>
      }

    </>
  )
}

export default CustomerCheckinForm