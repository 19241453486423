import api from "../../api/api";


const requestSMSOtp = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/customer/request-sms-otp",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :"res.data.msg"
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}


const resendSMSOtp = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/customer/resend-sms-otp",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :"res.data.msg"
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}

const verifySMSOtp = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/customer/verify-sms-otp",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :"res.data.msg"
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}







const customerOtpServices = {
    requestSMSOtp,
    resendSMSOtp,
    verifySMSOtp
}
export default customerOtpServices;