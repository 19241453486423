import {useState,useEffect} from 'react'
import portalServices from '../app/portal/portalServices';


function useBusinessInfo() {
    const [businessInfo,setBusineeInfo] = useState(null);
    const getBusinessInfo = () =>{
        let _businessInfo  =  JSON.parse(sessionStorage.getItem("businessInfo"));
        if(_businessInfo == null){
            console.log("get business info from use")
            portalServices.getBusinessInfo().then(res=>{
                setBusineeInfo(res.data);
                sessionStorage.setItem("businessInfo",JSON.stringify(res.data))
            })
        } else {
            //console.log("_businessInfo is ",_businessInfo)
            setBusineeInfo(_businessInfo);
        }
    }
    useEffect(()=>{
        getBusinessInfo();
    },[])
    return businessInfo;
}

export default useBusinessInfo