import React,{useState,useEffect, useRef} from 'react'
import AppHeader from '../components/AppHeader'
import portalServices from '../portalServices';
import { Link, useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';
import { ArrowRight, CalendarMonth, Person, WorkOutline } from '@mui/icons-material';
import SelfCancel from '../components/SelfCancel';


function TokenDetailsPage() {

  const [tokenList,setTokenList] = useState(null);
  const getTokenDetails = () =>{
    let customerToken = localStorage.getItem("customerToken");
    if(customerToken){
      portalServices.getTokenDetails({customerToken:customerToken}).then(res=>{
        setTokenList(res.data);
      }).catch(error=>{
        console.log(error)
      })
    }
  }

  useEffect(()=>{
    getTokenDetails();
  },[])


  const printComponentRef = useRef()
  const navigate = useNavigate();
  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
    pageStyle:"@page { size: 2.5in 2.5in; margin: 0; padding:0 }, @media print { @page {  size: 2.5in 2.5in margin: 0mm !important;  body,html{padding:0px;margin:0px;} } }",
    //pageStyle:"@media print { html, body { height: 2.5in; /* Use 100% here to support printing more than a single page*/ margin: 0 !important;padding: 0 !important;overflow: hidden;}}; @page { size: 2.5in 2.5in; margin: 0; }"
    //onAfterPrint:()=>{navigate("/kiosk/dashboard")}
  });

  return (
    <>
        <AppHeader/>
        <div className='container'>
          <div className='row mt-4'>
            <div className='col-md-12'>
              {tokenList?.map((tokenDetails,index) => 
                <div key={tokenDetails.id} className="card mb-2" >
                  <div className="card-body" >  
                      <div className="row mt-2" ref={printComponentRef}>
                          <div className="col-xs-12 text-center">
                              <div className="rounded  text-center">
                                  <h4 className="mt-2">{tokenDetails.prefix}-{tokenDetails.tokenNo}</h4>
                              </div>
                          </div>
                          <div className="col-xs-12 text-center">
                                  {tokenDetails.profileImage!== null &&
                                      <img src={tokenDetails.profileImage} className="img-thumbnail"
                                       style={{height:".8in", width:".8in"}}
                                       alt="Visitor Photo" />
                                  }
                                  <div style={{fontSize:10, lineHeight:0}} ><Person/>: {tokenDetails.firstName}</div>
                                  <div style={{fontSize:10, lineHeight:0}} ><CalendarMonth/> : {moment(tokenDetails.checkinDate).format("LL")}</div>
                                  <div style={{fontSize:10, lineHeight:0}} ><WorkOutline/> : {tokenDetails.queueTitle} <ArrowRight/> {tokenDetails.serviceTitle}</div>
                          </div>
                      </div>
                      <div className="col-md-12 mt-3">
                        <span className="float-end me-2">
                          <SelfCancel getTokenDetails={getTokenDetails} tokenDetails={tokenDetails} />
                          <button type="button" onClick={()=>handlePrint()} className="btn btn-success btn-lg" >Print</button>
                        </span>
                      </div>
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-12 text-center mt-3">
              <Link to={"/portal"} type="button"  className="btn btn-outline-success btn-lg" >Book Another Token</Link>
            </div>
          </div>
        </div>
    </>
  )
}

export default TokenDetailsPage