import React from 'react'
import useBusinessInfo from '../../../hooks/useBusinessInfo'

function AppHeader() {

    const businessInfo = useBusinessInfo();

    return (
        <div className='container-fluid'>
            {businessInfo &&
            <div className='row py-2 bg-light shadow mb-3' >
                <div className='offset-md-3 col-md-6 my-2 text-center'>
                    {businessInfo.logo !== null ?
                        <img src={businessInfo.logo} style={{maxHeight:80, width:"auto"}} />
                        :
                        <h3>{businessInfo.title}</h3>
                    }
                </div>
            </div>
            }
        </div>
    )
}

export default AppHeader