import React,{useState,useEffect} from 'react'
import AppHeader from '../components/AppHeader'
import portalServices from '../portalServices';
import { useNavigate, useParams } from 'react-router-dom';

function SelectServicesPage() {

  const {queueId} = useParams();
  const navigate = useNavigate();

  const [serviceList,setServiceList] = useState(null);
  const getServiceList = () =>{
    portalServices.getServiceList(queueId).then(res=>{
      setServiceList(res.data);
    }).catch(error=>{

    })
  }
  useEffect(()=>{
    getServiceList();
  },[])

  const selectService = (service) =>{
    
      navigate("/checkin/"+queueId+"/"+service.id)
    
  }


  return (
    <>
        <AppHeader/>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                    {serviceList && serviceList.map(service=>
                      <div key={service.id} className="card px-3 py-2 mb-2" onClick={()=>selectService(service)} style={{cursor:"pointer"}}>
                          <div className='fw-bold'>{service.title}</div>
                      </div>
                    )}
                </div>
            </div>
        </div>
    </>
  )
}

export default SelectServicesPage