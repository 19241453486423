import api from "../../api/api";


const getBusinessInfo = () =>{
    return new Promise((resolve, reject)=>{
        api.get("/customer/portal/business-info").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :"res.data.msg"
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}


const getQueueList = () =>{
    return new Promise((resolve, reject)=>{
        api.get("/customer/portal/queue").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :"res.data.msg"
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}

const getServiceList = (queueId) =>{
    return new Promise((resolve, reject)=>{
        api.get("/customer/portal/service/"+queueId).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :"res.data.msg"
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}

const getFormConfig = (queueId) =>{
    return new Promise((resolve, reject)=>{
        api.get("/customer/portal/form-config/"+queueId).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :"res.data.msg"
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}

const doCheckin = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/customer/portal/checkin",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :"res.data.msg"
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}

const getTokenDetails = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/customer/portal/token-details",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :"res.data.msg"
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}


const cancelToken = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/customer/portal/cancel-token",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :"res.data.msg"
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}

const portalServices = {
    getBusinessInfo,
    getQueueList,
    getServiceList,
    getFormConfig,
    doCheckin,
    getTokenDetails,
    cancelToken
}
export default portalServices;