import React, { useState } from 'react'
import { AlertProvider } from './gtoken-k/context/AlertContext';

import { Route, Routes } from 'react-router'
import { SocketProvider } from './gtoken-k/context/SocketContext';
import PortalLandingPage from './gtoken-k/app/portal/pages/PortalLandingPage';
import SelectQueuePage from './gtoken-k/app/portal/pages/SelectQueuePage';
import SelectServicesPage from './gtoken-k/app/portal/pages/SelectServicesPage';
import CheckinFormPage from './gtoken-k/app/portal/pages/CheckinFormPage';
import TokenDetailsPage from './gtoken-k/app/portal/pages/TokenDetailsPage';
import PortalRootPage from './gtoken-k/app/portal/pages/PortalRootPage';

function App() {
    
    //console.log("authUSerrole", authUser)
    sessionStorage.removeItem("businessInfo");
    return (
        <>
        <AlertProvider>
                <SocketProvider>
                    <Routes>
                        <Route path="/" element={ <PortalRootPage/> } />
                        <Route path="/portal/:portalId" element={ <PortalLandingPage/> } />
                        <Route path="/portal" element={ <SelectQueuePage/> } />
                        <Route path="/services/:queueId" element={ <SelectServicesPage/> } />
                        <Route path="/checkin/:queueId/:serviceId" element={ <CheckinFormPage/> } />
                        <Route path="/token-details" element={ <TokenDetailsPage/> } />
                    </Routes> 
                </SocketProvider>
        </AlertProvider>
        </>
    )
}
export default App