import axios from 'axios';

//const baseURL = process.env.REACT_APP_BASE_URL;


let portalUrl = localStorage.getItem("portalUrl");
let portalId = localStorage.getItem("portalId");
const baseURL = portalUrl;

const API = axios.create({
    baseURL: baseURL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With' : portalId
      },
});

API.interceptors.request.use(
    config => {
        if(localStorage.authUser){  // If user is Loggedin 
            let authUser = JSON.parse(localStorage.authUser);
            let token = authUser.token;
            config.headers.Authorization = `Bearer ${token}`;
            //config.headers['X-Requested-With'] = 1;
      } else {
        delete API.defaults.headers.common.Authorization;
      }
      return config;
    },
    error => Promise.reject(error)
);

export default API;