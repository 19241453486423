import { Dialog, DialogContent, LinearProgress } from '@mui/material'
import React, { useState } from 'react'
import portalServices from '../portalServices';
import { useSocket } from '../../../context/SocketContext';
import io from 'socket.io-client'

function SelfCancel({getTokenDetails, tokenDetails}) {
    const [dialogStatus,setDialogStatus] = useState(false);
    const [loading,setLoadingStatus] = useState(false);
    const {connectionUrl} = useSocket()
    const confirmCancel = () => {
        setLoadingStatus(true)
        let payload = {
            tokenId : tokenDetails.id,
            customerToken : localStorage.getItem('customerToken')
        }
        portalServices.cancelToken(payload).then(res=>{
            if(res.updateStatus > 0){
                getTokenDetails();
                //socket.emit("send_close_token",{message:"token cancelled"})
                socketEmit()
            }
        }).catch(erro=>{

        }).finally(()=>{
            setLoadingStatus(false);
        })
    }

    const socketEmit = ()=>{
        let socket = io.connect(connectionUrl)
        socket.emit("send_close_token",{message:"token cancelled"})
          setTimeout(() => {
            socket.close()
          }, 5000);
      }
    return (
        <>
            <button className='btn btn-outline-danger btn-lg me-2' onClick={()=>setDialogStatus(true)} >Cancel</button>
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={()=>setDialogStatus(false)}
            >
                <DialogContent>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h4>Are you Sure you want to cancel this token</h4>
                        </div>
                        {loading ?
                            <div className='col-md-12'>
                                <LinearProgress />
                            </div>
                            :
                            <div className='col-md-12'>
                                <button className='btn btn-danger float-end' onClick={confirmCancel}>Yes</button>
                                <button className='btn btn-outline-primary' onClick={()=>setDialogStatus(false)} >No</button>
                            </div>
                        }
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default SelfCancel