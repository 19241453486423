import React, { useEffect, useState } from 'react'
import customerOtpServices from '../customerOtpServices';
import { LinearProgress } from '@mui/material';
import { useParams } from 'react-router-dom';

function OTPLoginPage({queueId,serviceId, setCustomerToken} ) {

    const [phone,setPhone] = useState("");
    const [otp,setOtp] = useState("");
    const [invalidOtpError,setInvalidOtpError] =  useState(false);

    const [loading,setLoadingStatus] = useState(false);
    const [resendTimer,setResendTimer] = useState(0);
    const [otpSent,setOtpSent] = useState(false);

    useEffect(()=>{
        if(resendTimer > 0 ){
            setTimeout(()=>{
                let _resendTimer = resendTimer-1;
                setResendTimer(_resendTimer)
            },1000)
        }
    },[resendTimer])
        
    const requestOtp = () =>{
        setLoadingStatus(true);
        customerOtpServices.requestSMSOtp({phone:phone}).then(res=>{
            setOtpSent(true);
            setResendTimer(60);
        }).catch(()=>{

        }).finally(()=>{
            setLoadingStatus(false)
        })
    }

    const resendOtp = () =>{
        setLoadingStatus(true);
        customerOtpServices.resendSMSOtp({phone:phone}).then(res=>{
            setOtpSent(true);
            setResendTimer(90);
        }).catch(()=>{

        }).finally(()=>{
            setLoadingStatus(false)
        })
    }

    

    const verifyOtp = () =>{
        setLoadingStatus(true);
        let payload = {
            phone:phone,
            otp:otp,
            queueId:queueId,
            serviceId:serviceId,
        }
        customerOtpServices.verifySMSOtp(payload).then(res=>{
            setCustomerToken(res.data.token)
            
        }).catch((error)=>{
            setInvalidOtpError(true);
        }).finally(()=>{
            setLoadingStatus(false)
        })
    }

    return (
        <div className='card p-2 shadow'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className="form-floating mb-3 mt-3">
                        <input name="phone" className="form-control" type='text' value={phone} onChange={(e)=>setPhone(e.target.value)}></input>
                        <label htmlFor="phone">Your Phone No</label>
                    </div>
                    {otpSent &&
                        <p className='text-success text-center'>OTP Sent on {phone}</p>
                    }
                    { otpSent &&
                        <div className="form-floating mb-3 mt-3">
                            <input name="otp" className="form-control" type='text' value={otp} onChange={(e)=>setOtp(e.target.value)}></input>
                            <label htmlFor="otp">Please Enter OTP</label>
                        </div>
                    }
                    {invalidOtpError &&
                        <p className='text-danger text-center'>Invalid OTP</p>
                    }
                    { loading && <LinearProgress/> }
                </div>
                <div className='col-md-12'>
                    {!otpSent ?
                        <button disabled={phone.length !== 10 || isNaN(phone) || loading } type='button' onClick={requestOtp}  className='btn btn-outline-primary float-end'>Request OTP</button>                
                        :
                        <>
                            <button disabled={resendTimer > 0 || phone.length !== 10 || isNaN(phone) || loading } type='button' onClick={resendOtp}  className='btn btn-outline-primary '>
                                Resend OTP      
                            {resendTimer > 0 ?  " in ("+resendTimer+"s)" : "" }
                            </button>
                            <button disabled={otp.length !== 4 || isNaN(otp)} type='button' onClick={verifyOtp}  className='btn btn-primary float-end'>Verify</button>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default OTPLoginPage