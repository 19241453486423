import { CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

function PortalRootPage() {

    const [error,setError] = useState(false);
    const navigate = useNavigate();
    useEffect(()=>{
        let portalId = localStorage.getItem("portalId");
        console.log("portalid",portalId);
        if(portalId == null){
            setError(true);
        } else{
            navigate("/portal")
        }
    },[])

  return (
        <div className="container">
            <div className="row mt-5">
                <div className='col-md-12 text-center'>
                    <img src='/images/gtoken-logo.jpg' style={{width:"30%"}} />
                </div>
                <div className="col-md-12 text-center">
                    <CircularProgress/>
                </div>
                {error &&
                <div className="col-md-12 text-center">
                    <p>Please Scan QR Code </p>
                </div>
                }
            </div>
        </div>
  )
}

export default PortalRootPage