import React, { useEffect, useState } from 'react'
import AppHeader from '../components/AppHeader'
import { useParams } from 'react-router-dom'
import OTPLoginPage from '../components/OTPLoginPage';
import CustomerCheckinForm from '../components/CustomerCheckinForm';

function CheckinFormPage() {

  const {queueId,serviceId} = useParams();
  const [customerToken,setCustomerToken] =  useState(null);

  return (
    <>
        <AppHeader/>
        <div className='container'>
          {
            customerToken === null ?
              <OTPLoginPage
                queueId={queueId}
                serviceId={serviceId}
                setCustomerToken={setCustomerToken}
              /> 
            :
            <CustomerCheckinForm
              queueId={queueId}
              serviceId={serviceId}
              customerToken={customerToken}
            />
          }
        </div>
    </>
  )
}

export default CheckinFormPage